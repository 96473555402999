import React, { useState } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import dimensions from "styles/dimensions"
import Layout from "components/Layout"
import "../styles/gallery.scss"
import ButtonBack from "../components/_ui/ButtonBack"

// importing photoswipe
import "react-photoswipe/lib/photoswipe.css"
import { PhotoSwipe } from "react-photoswipe"

const ProjectHeroContainer = styled("div")`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 120px;
  transition: all 150ms ease-in-out;

  text-decoration: none;
  color: currentColor;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    flex-direction: column-reverse;
    margin-bottom: 56px;
  }
`
const ProjectHeroContent = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  width: 50%;
  background: white;

  h1 {
    font-family: "NewEddy", "Inter", sans-serif;
    font-weight: normal;
    width: 100%;
    z-index: 1;
    margin: 0 0 8px 0;
  }

  h6 {
    text-transform: uppercase;
  }

  p {
    z-index: 1;
  }

  @media (max-width: 950px) {
    width: 100%;
    padding: 32px 0 0 0;
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    h1 {
      font-size: 3em;
    }
  }
`

const ProjectHeroImageContainer = styled("div")`
  display: flex;
  justify-content: start;
  align-items: center;

  width: 50%;
  overflow: hidden;
  position: relative;

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  img {
    max-height: 70vh;
    max-width: 90%;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
      max-height: unset;
    }
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    img {
      max-width: 100%;
    }
  }
`

const ProjectImageGallery = styled("div")`
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  & img {
    max-width: 100%;
  }

  & button {
    background: none;
    border: none;
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-template-columns: repeat(6, 1fr);
  }
`
const DescriptionContainer = styled("div")`
  grid-column-start: 6;
  grid-column-end: 11;

  grid-row-start: 3;

  position: relative;
  top: -80px;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-column-start: 1;
    grid-column-end: 7;
    top: 0;
    margin: 48px 0;
  }
`

const BackgroundTitle = styled("div")`
  grid-column-start: 8;
  grid-column-end: 13;

  grid-row-start: 1;

  position: relative;
  left: 24px;
  z-index: 1;
  align-self: center;
  white-space: nowrap;
  z-index: -1;
  line-height: 0.6;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-column-start: 1;
    grid-column-end: 7;
    left: -24px;
    top: 16px;
    align-self: center;
  }
`

const BackgroundProfession = styled("div")`
  grid-column-start: 1;
  grid-column-end: 13;

  grid-row-start: 5;

  position: relative;
  left: -200px;
  top: -100px;
  z-index: 1;
  align-self: center;
  white-space: nowrap;
  z-index: -1;
  line-height: 0.6;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    grid-column-start: 1;
    grid-column-end: 7;
    top: 180px;
  }
`

const VideoContainer = styled("div")``

const Project = ({ project, meta }) => {
  // photoswipe setup
  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState({})

  let items = project.image_block_top.map(img => {
    return {
      src: img.gallery_image.url,
      w: img.gallery_image.dimensions.width,
      h: img.gallery_image.dimensions.height,
    }
  })

  const handleClose = () => {
    setIsOpen(false)
  }

  const openPhotoSwipe = (id, e) => {
    e.preventDefault()
    setOptions({
      closeOnScroll: false,
      fullscreenEl: false,
      shareEl: false,
      zoomEl: false,
      index: id,
    })
    setIsOpen(true)
  }

  return (
    <>
      <Helmet
        title={`${project.project_title[0].text} | Event & Artist agency`}
        titleTemplate={`%s | ${meta.title}`}
        meta={[
          {
            name: `description`,
            content: meta.description,
          },
          {
            property: `og:title`,
            content: `${project.project_title[0].text} | Event & Artist agency`,
          },
          {
            property: `og:description`,
            content: meta.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: meta.author,
          },
          {
            name: `twitter:title`,
            content: meta.title,
          },
          {
            name: `twitter:description`,
            content: meta.description,
          },
        ].concat(meta)}
      />
      <Layout>
        <ProjectHeroContainer>
          <ProjectHeroContent className="ArtistHeroContent">
            <h1>{project.project_title[0].text}</h1>
            {RichText.render(project.project_preview_description)}
          </ProjectHeroContent>

          {project.project_hero_image && (
            <ProjectHeroImageContainer
              onClick={e => openPhotoSwipe(0, e)}
              className="ArtistCardImageContainer imageWrapper"
            >
              <img
                src={project.project_hero_image.url}
                alt={project.project_title[0].text}
              />
            </ProjectHeroImageContainer>
          )}
        </ProjectHeroContainer>

        <ProjectImageGallery>
          {project.image_block_top.map((img, i) => {
            return (
              <button
                className={
                  project.project_media_link && i > 2
                    ? "imageWrapper galleryImage-" + (i + 1)
                    : "imageWrapper galleryImage-" + i
                }
                onClick={e => openPhotoSwipe(i, e)}
                key={i}
              >
                <img
                  src={project.image_block_top[i].gallery_image.url}
                  alt={project.project_title[0].text}
                />
              </button>
            )
          })}

          <BackgroundTitle className="decoXXL">
            {project.project_title[0].text}
          </BackgroundTitle>

          <DescriptionContainer>
            {RichText.render(project.project_description)}
          </DescriptionContainer>

          <BackgroundProfession className="decoXXL">
            {project.backdrop_headline[0].text}
          </BackgroundProfession>

          {project.project_media_link && (
            <VideoContainer className="imageWrapper galleryImage-3">
              <video
                controls
                autoPlay="true"
                width="100%"
                loop
                muted
                disablePictureInPicture
              >
                <source src={project.project_media_link.url} type="video/mp4" />
              </video>
            </VideoContainer>
          )}
        </ProjectImageGallery>

        <ButtonBack />
      </Layout>
      <PhotoSwipe
        isOpen={isOpen}
        items={items}
        options={options}
        onClose={handleClose}
      />
    </>
  )
}

export default ({ data }) => {
  const projectContent = data.prismic.allProjects.edges[0].node
  const meta = data.site.siteMetadata
  return <Project project={projectContent} meta={meta} />
}

Project.propTypes = {
  project: PropTypes.object.isRequired,
}

export const query = graphql`
  query ProjectQuery($uid: String) {
    prismic {
      allProjects(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            backdrop_headline
            project_description
            project_hero_image
            image_block_top {
              gallery_image
            }
            project_preview_description
            project_title
            project_media_link {
              ... on PRISMIC__FileLink {
                url
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
