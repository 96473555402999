import React, { Component } from "react"
import styled from "@emotion/styled"
import dimensions from "styles/dimensions"

const ButtonContainer = styled("div")`
  width: 100%;
  text-align: center;
`
const Button = styled("button")`
  width: auto;
  height: 220px;
  padding: 2em 0;
  margin: 0 auto;
  text-align: center;
  color: #000;
  background: none;
  border: none;
  font-size: 32px;
  font-family: "NewEddy", "SuisseIntl", "Inter", sans-serif;
  font-weight: normal;
  transition: text-decoration 1s ease-in-out, font-size 1s ease-in-out;
  z-index: 2;
  position: relative;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    top: -130px;
  }

  & :after {
    content: "";
    width: 0px;
    height: 3px;
    display: block;
    background: black;
    transition: 300ms;
  }

  & :hover {
    cursor: pointer;
  }

  & :hover:after {
    width: 100%;
  }
`

class ButtonBack extends Component {
  goBack() {
    window.history.back()
  }
  render() {
    return (
      <ButtonContainer>
        <Button onClick={this.goBack}>←← Back</Button>
      </ButtonContainer>
    )
  }
}

export default ButtonBack
